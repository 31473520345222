import { Box, Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router';
import { CommonLayout } from '../commonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLES } from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import DoughnutGraph from './doughnutGraph';
import MonthOnMonthAndOnboardingStatus from './monthOnMonthAndStatus';
import TopDistributorRM from './topDistributorsRM';
import CommitmentAndContribution from './commitmentAndContribution';
import DashboardAndApplicationsList from './applicationslist';
import { useEffect, useState } from 'react';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { getAllApplications } from '../../redux-store/actions/application';
import { GetAllApplicantionsResponseBody } from '../../redux-store/types/application';

export default function Dashboard(): JSX.Element {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [applicationsList, setApplicationsList] = useState<ApplicationProps[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllApplications({
            page: 1,
            limit: 4,
          })
        )) as unknown as GetAllApplicantionsResponseBody;
        const { applications } = response || {};
        setLoading(false);
        if (!componentIsAlive) {
          return;
        }
        setApplicationsList(applications);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  return (
    <CommonLayout>
      <>
        <Grid container spacing={4}>
          <DoughnutGraph />
          <MonthOnMonthAndOnboardingStatus />
        </Grid>
        {/* <Grid container spacing={4} py={4}>
          {USER_ROLES.AMC_ADMIN === role && <TopDistributorRM />}
          <CommitmentAndContribution />
        </Grid> */}
        <Box>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: 1 }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Recent Applications
            </Typography>

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 14,
                mr: { xs: 1, sm: 2.5 },
                p: 1,
              }}
              onClick={() => history.push('/onboarding')}>
              Add Applicant
            </Button>
          </Box>
          <Box mt={3} sx={{ maxWidth: { lg: '100%' } }}>
            <DashboardAndApplicationsList
              isLoading={isLoading}
              applicationsList={applicationsList}
              boxStyles={{ minHeight: '350px', minWidth: 450 }}
              DashboardApplicationsList={true}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 5 }}>
            {/* <IconButton to={{ pathname: '/applications' }} component={RouterLink}> */}
            <Button
              variant="contained"
              sx={{
                maxWidth: 20,
                maxHeight: 30,
                minWidth: 200,
                minHeight: 40,
              }}
              onClick={() => history.push('/applications')}>
              View More
            </Button>
            {/* </IconButton> */}
          </Box>
        </Box>
      </>
    </CommonLayout>
  );
}
