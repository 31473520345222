/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { validateYupSchema } from 'formik';
import { bankObject } from '../../components/investors/bankDetails';
//import { getDocumentDetails } from '../../components/investors/documentDetails';
import { contactPersonObject } from '../../components/NonIndividualInvestor/ContactDetails';
import { document_object } from '../../components/NonIndividualInvestor/DocumentDetails';
import { percentageError } from '../../components/NonIndividualInvestor/ubo';
import {
  APMIFlow,
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  CHECKER_EDIT_ALL_SECTIONS,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  Liquid_FeeType,
  occupationDetailsMasters,
  relatedPartyConsent,
  salutationsMasters,
  USER_ROLES,
  YesNoMaster,
} from '../../utils/constant';
import { defaultRegexWithSingleLine, emailRegex, individualPanRegex } from '../../utils/regex';
import {
  bankDetailsSchema,
  contributorDetailsSchema,
  disclosureOfExcludedSchema,
  disclosureOfInterestSchema,
  documentDetailsSchema,
  documentDetailsSchemaForInvestorLogin,
  FATCAValidationSchema,
  KYCDetailsSchema,
  nomineeDetailsSchema,
  nonIndividualContactDetailsSchema,
  NonIndividualContributorValidationSchema,
  nonIndividualDocumentDetailsSchema,
  nonIndividualFatcaSchema,
  relatedPartyConsentSchema,
  strategyDeclarationScheme,
  StrategyDetailSchema,
} from '../../utils/schema';
import {
  checkStrategyBoxSelected,
  checkStrategyFeeSlabSelected,
  checkStrategyFeeTypeSelected,
  checkIfApplicationIsNonIndividual,
  documentNameValidationCheckForMultipleDocumentsMandatory,
  fieldValidateForInvestor,
  getAddressData,
  getNomineeRelation,
  getOccupationType,
  getRelation,
  isMinor,
  containsLiquid,
} from '../../utils/utilityFunctions';
import {
  AuthorisedErrors,
  BankError,
  CALL_API,
  UboErrors,
  RiskProfileErrors,
  StrategyErrors,
  RelatedPartyConsentErrors,
  FatcaErrors,
  fatcaErrors,
  DisclosureOfInterestErrors,
  DisclosureOfExclusionsErrors,
} from '../middleware';
import { useDispatch, useSelector } from 'react-redux';

import {
  Applicant,
  ApplicationProps,
  Bank,
  NomineeType,
  Document,
  uboTypes,
  FetchUBORequestBody,
  StampPapersType,
  dashboardOnboardingType,
  monthwiseOnboardingSummaryType,
  distributorWisecommitmentType,
  monthwiseCommitmentAmountType,
  ubo,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  Groups,
  GroupSignatories,
  RiskProfileMasterType,
  TimeLineType,
  getDetailsByRefrenceType,
  StrategyType,
  FeeSlabsType,
  FeeType,
  nonIndividualMdmsQuestionsFatca,
  getAuthorizedSignatoriesDetailsByRefrenceType,
  monthwiseTotalInvestmentAmountAndApplicantsType,
} from '../types/api-types';
import {
  CreateApplicationRequestBody,
  CREATE_APPLICATION,
  CREATE_APPLICATION_SUCCESS,
  GET_APPLICATION,
  GET_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_SUCCESS,
  GET_STAMPPAPER_DETAILS_SUCCESS,
  GET_STAMPPAPER_DETAILS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT,
  GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS,
  GET_MONTHWISE_COMMITMENTAMOUNT,
  GET_ONBOARDING_SUMMARY_SUCCESS,
  GET_ONBOARDING_SUMMARY,
  GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS,
  GET_MONTHWISE_ONBOARDING_SUMMARY,
  GET_ALL_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION,
  AddJointHolderRequestBody,
  ADD_APPLICANT_SUCCESS,
  ADD_APPLICANT,
  GetAllApplicantsRequestBody,
  GetAllApplicantionsResponseBody,
  PENNY_DROP_VERIFICATION_SUCCESS,
  PENNY_DROP_VERIFICATION,
  GET_Documents_SUCCESS,
  GET_Documents,
  GET_Ubo_SUCCESS,
  GET_Ubo,
  UBO_LISTING_SUCCESS,
  UBO_LISTING,
  GET_Fatca_SUCCESS,
  GET_Fatca,
  TYPED_DECLARATION_SUCCESS,
  TYPED_DECLARATION,
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  GET_COMPANY_SUCCESS,
  GET_COMPANY,
  AUTHORIZED_SIGNATORIES_TYPED_DECLARATION_SUCCESS,
  AUTHORIZED_SIGNATORIES_TYPED_DECLARATION,
  GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS,
  GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS,
} from '../types/application';
import {
  mdmsCompaniesList,
  mdmsCountriesList,
  nationaliyType,
  ValidationOrConfigMaster,
} from '../types/mdms';
import { GetStrategiesResponseBody } from '../types/strategies';
import { newStrategy } from '../../components/investors/strategyDetails';
import {
  handleIntialSelect,
  RiskProfileObj,
  updatedRiskProfile,
} from '../../components/investors/riskProfileDetails';
import { relatedDataArr } from '../../components/investors/relatedPartyDetails';
import { newNominee } from '../../components/investors/nomineeDetails';
import {
  investorEditDisclosureOfExclusionObj,
  investorEditDisclosureOfInterestObj,
  investorEditDocumentDetailsObj,
  investorEditRiskProfileObj,
} from '../../components/investors/components';
import { InvestorEditErrorObj, InvestorEditSectionProps } from '../reducers/investorEditSections';
import {
  AssignAnsAndVisibilityForInitialFatca,
  validationPopUp,
} from '../../components/NonIndividualInvestor/fatca';
import { documentDetails } from '../../components/investors/documentDetails';
import { sectionFilledByInvestor } from '../../utils/declaration';
export const createApplication =
  (body: CreateApplicationRequestBody) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'POST',
        types: [CREATE_APPLICATION_SUCCESS, CREATE_APPLICATION],
        body,
      },
    });
  };

export const getApplicationDetails =
  (applicationId: string, method = '') =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}`,
        method: method ? 'DELETE' : 'GET',
        types: [GET_APPLICATION_SUCCESS, GET_APPLICATION],
      },
    });
  };

export const getTimeLineDetails =
  (applicationId: string) =>
  async (dispatch: any): Promise<TimeLineType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}/activity-logs`,
        method: 'GET',
        types: [GET_TIMELINE_SUCCESS, GET_TIMELINE],
      },
    });
  };

export const getParams = (paramsObj: Partial<GetAllApplicantsRequestBody>) => ({
  type: 'GET_PARAMS_DATA',
  paramsObj,
});
export const getAllApplications =
  (params: Partial<GetAllApplicantsRequestBody>) =>
  async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
    await dispatch(getParams(params));
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'GET',
        types: [GET_ALL_APPLICATION_SUCCESS, GET_ALL_APPLICATION],
        params: {
          limit: 10,
          sort: 'createdAt',
          order: 'DESC',
          ...params,
        },
      },
    });
  };

export const onboardingSummary =
  () =>
  async (dispatch: any): Promise<dashboardOnboardingType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/onboardingSummary`,
        method: 'GET',
        types: [GET_ONBOARDING_SUMMARY_SUCCESS, GET_ONBOARDING_SUMMARY],
      },
    });
  };

export const monthwiseOnboardingSummary =
  () =>
  async (dispatch: any): Promise<monthwiseOnboardingSummaryType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseOnboardingSummary?months=4`,
        method: 'GET',
        types: [GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS, GET_MONTHWISE_ONBOARDING_SUMMARY],
      },
    });
  };

export const distributorWisecommitmentAmount =
  () =>
  async (dispatch: any): Promise<distributorWisecommitmentType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/distributorWisecommitmentAmount`,
        method: 'GET',
        types: [GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS, GET_DISTRIBUTOR_COMMITMENTAMOUNT],
      },
    });
  };

export const monthwiseCommitmentAmount =
  () =>
  async (dispatch: any): Promise<monthwiseCommitmentAmountType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseCommitmentAmount`,
        method: 'GET',
        types: [GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS, GET_MONTHWISE_COMMITMENTAMOUNT],
      },
    });
  };

export const monthwiseTotalInvestmentAmountAndApplicants =
  () =>
  async (dispatch: any): Promise<monthwiseTotalInvestmentAmountAndApplicantsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseTotalInvestmentAmountAndApplicants`,
        method: 'GET',
        types: [
          GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS,
          GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS,
        ],
      },
    });
  };

// export const getStampPaperCount =
//   (params: Partial<GetAllApplicantsRequestBody>) =>
//   async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
//     return await dispatch({
//       [CALL_API]: {
//         url: `/leegality/stampPaperDetails`,
//         method: 'GET',
//         types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],

//       },
//     });
//   };

export const getStampPaperCount =
  () =>
  async (dispatch: any): Promise<StampPapersType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/leegality/stampPaperDetails`,
        method: 'GET',
        types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],
      },
    });
  };

export const updateApplication =
  ({
    body,
    applicationId,
    toastMessage,
  }: {
    body: Partial<ApplicationProps>;
    applicationId: string;
    toastMessage?: string;
  }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    const showToast = typeof toastMessage !== 'undefined';
    if (showToast) {
      toastMessage =
        `Application ${body.applicationNumber || ''} - ` + (toastMessage || ' Saved successfully');
    }
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/applications/${applicationId}/update`,
          method: 'POST',
          types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
          body,
          showToast,
          toastMessage,
        },
      });
    } catch (error: any) {
      console.log('catch error on update application', error);
      throw error;
    }
  };

export const addJointHolder =
  (body: AddJointHolderRequestBody, applicationId: string) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}/addApplicant`,
        method: 'POST',
        types: [ADD_APPLICANT_SUCCESS, ADD_APPLICANT],
        body,
      },
    });
  };

export const sendTypedDeclaration =
  (
    body: {
      typedDeclaration: string;
    },
    applicantId: string
  ) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/typed-declaration/${applicantId}`,
        method: 'POST',
        types: [TYPED_DECLARATION_SUCCESS, TYPED_DECLARATION],
        body,
      },
    });
  };

export const authorizedSignatoriesTypedDeclaration =
  (
    body: {
      groupSignatoryConsent: boolean;
      groupsignatorydocuments: documentDetails[];
    },
    refId: string
  ) =>
  async (dispatch: any): Promise<getAuthorizedSignatoriesDetailsByRefrenceType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${refId}/group-signatory/uploadDocumentsAndConsent`,
        method: 'POST',
        types: [
          AUTHORIZED_SIGNATORIES_TYPED_DECLARATION_SUCCESS,
          AUTHORIZED_SIGNATORIES_TYPED_DECLARATION,
        ],
        body,
      },
    });
  };

export const validateAdditionalDetails = async (applicants: Partial<Applicant>[], role: any) => {
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      ...applicant,
      nationality: applicant.nationality
        ? applicant.nationality
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'Indian',
      namePrefix:
        applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
          ? applicant.namePrefix
          : 'Mr.',
      jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
        ? applicant.relationShipWithFirstApplicant
        : 'others',
      relationShipWithFirstApplicant: getRelation(applicant.relationShipWithFirstApplicant)
        ? ''
        : applicant.relationShipWithFirstApplicant,
      occupationType: getOccupationType(applicant.occupationDetails)
        ? applicant.occupationDetails
        : 'others',
      occupationDetails:
        occupationDetailsMasters[applicant.occupationDetails || ''] ===
        occupationDetailsMasters.others
          ? ''
          : getOccupationType(applicant.occupationDetails)
          ? ''
          : applicant.occupationDetails,
      maritalStatus: applicant.maritalStatus || '',
      aadhaarNumber: applicant.aadhaarNumber || '',
      aadhaarCheck: applicant.aadhaarCheck || '',
      educationalQualification: applicant.educationalQualification || '',
      taxAssesseeCheck: applicant.taxAssesseeCheck || '',
    })),
  };
  try {
    await validateYupSchema(schemaData, contributorDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Applicant(s) details`;
  }
};

export const validateKYCDetails = async (application: ApplicationProps | null, role: any) => {
  const schemaData = {
    ownBorrowedFunds: application?.ownBorrowedFunds || '',
    applicants: application?.applicants.map((applicant) => ({
      grossAnnualIncome: applicant.grossAnnualIncome || '',
      politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
        ? applicant.politicallyExposedPersonStatus
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'Not Applicable',
      ckycNo: applicant.ckycNo || '',
      grossAnnualIncomeDate: applicant.grossAnnualIncomeDate || null,
      netWorthDate: applicant.netWorthDate || null,
      netWorth: applicant.netWorth || '',
    })),
  };
  try {
    await validateYupSchema(schemaData, KYCDetailsSchema, true, schemaData);
  } catch (error) {
    throw `KYC details`;
  }
};

export const validateFATCA = async (
  applicants: Partial<Applicant>[],
  role: any,
  nationalitiesMdmsMasters: nationaliyType
) => {
  const schemaData = {
    applicants: applicants?.map((applicant) => ({
      typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
      taxResidentOfAnyCountryOtherThanIndia:
        applicant.taxResidentOfAnyCountryOtherThanIndia || false,
      placeOfBirth: applicant.placeOfBirth || '',
      countryOfBirth: applicant.countryOfBirth
        ? applicant.countryOfBirth.toUpperCase()
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      countryOfNationality: applicant.countryOfNationality
        ? applicant.countryOfNationality.toUpperCase()
        : [USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      taxCountryName: applicant.taxCountryName || '',
      taxID: applicant.taxID || '',
      idType: applicant.idType || '',
      nameOfEntity: applicant.nameOfEntity || '',
      dateOfIncorporation: applicant.dateOfIncorporation || '',
      cityOfIncorporation: applicant.cityOfIncorporation || '',
      countryOfIncorporation: applicant.countryOfIncorporation || '',
      entityExcemptionCode: applicant.entityExcemptionCode || '',
      poaMandateHolderOutsideIndia: applicant.poaMandateHolderOutsideIndia || 'no',
      addressTelephoneOutsideIndia: applicant.addressTelephoneOutsideIndia || 'no',
      fatcaDeclaration: applicant.fatcaDeclaration || false,
    })),
    countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
  };
  try {
    await validateYupSchema(schemaData, FATCAValidationSchema, true, schemaData);
    // applicants.map((applicant, ind) => {
    //   if (!applicant.fatcaDeclaration && role === USER_ROLES.INVESTOR && ind === 0) {
    //     throw new FatcaErrors('In Fatca Declaration is Required By first applicant');
    //   }
    //   if (!applicant.fatcaDeclaration && role === USER_ROLES.INVESTOR && ind === 1) {
    //     throw new FatcaErrors('In Fatca Declaration is Required By second applicant');
    //   }
    //   if (!applicant.fatcaDeclaration && role === USER_ROLES.INVESTOR && ind === 2) {
    //     throw new FatcaErrors('In Fatca Declaration is Required By third applicant');
    //   }
    // });
  } catch (e) {
    if (e instanceof FatcaErrors) {
      throw e;
    }
    throw 'FATCA';
  }
};

export const validateNomineeDetails = async (
  Nominees: Partial<NomineeType>[],
  doNotWishToNominate: boolean,
  applicants: Partial<Applicant>[],
  modeOfHolding: string,
  nationalitiesMdmsMasters: nationaliyType
) => {
  const schemaData = {
    doNotWishToNominate: Nominees.length
      ? false
      : doNotWishToNominate === null
      ? true
      : doNotWishToNominate,
    nominees: Nominees.length
      ? Nominees.map((nominee, index) => ({
          ...nominee,
          Relationship: getNomineeRelation(nominee.nomineeRelationship?.toLowerCase())
            ? nominee.nomineeRelationship
              ? nominee.nomineeRelationship.toLowerCase()
              : nominee.nomineeRelationship
            : 'others',
          nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toLowerCase())
            ? ''
            : nominee.nomineeRelationship,
          sno: index,
          nomineePercentage: nominee.nomineePercentage
            ? nominee.nomineePercentage
            : Nominees.length === 1
            ? 100
            : 0,
          guardianRelationship: getNomineeRelation(nominee.guardianRelationship?.toLowerCase())
            ? nominee.guardianRelationship
              ? nominee.guardianRelationship.toLowerCase()
              : nominee.guardianRelationship
            : 'others',
          otherGuardianRelationship: getNomineeRelation(nominee.guardianRelationship?.toLowerCase())
            ? ''
            : nominee.guardianRelationship,
        }))
      : [],
    countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
  };
  try {
    await validateYupSchema(schemaData, nomineeDetailsSchema(applicants, true), true, schemaData);
  } catch (error) {
    throw `Nominee details`;
  }
};

export const validateBankDetails = async (
  bankDetails: Partial<Bank>[],
  verifyPennydrop: boolean,
  applicationType: string,
  applicants: Partial<Applicant>[],
  createdAt: string,
  micrValidation: string
) => {
  const schemaData = {
    applicationType: applicationType,
    status: applicants.length ? applicants[0].status : '',
    banks: bankDetails.length
      ? bankDetails.map((bank) => ({ ...bank, defaultBankAccount: !!bank.defaultBankAccount }))
      : [bankObject],
  };
  try {
    if (IS_PENNYDROP_APPLICABLE_FOR_AMC && verifyPennydrop) {
      const isAllBanksPennyChecked = bankDetails.every((bank) => bank.pennydropCheck);
      if (!isAllBanksPennyChecked) {
        throw new BankError('Please make sure that all the banks are verified');
      }
    }
    await validateYupSchema(
      schemaData,
      bankDetailsSchema(applicants, createdAt, micrValidation),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof BankError) {
      throw error;
    }
    throw `Bank details`;
  }
};

export const validateRiskProfile = async (
  application: ApplicationProps | null,
  riskProfileDataMaster: RiskProfileMasterType[],
  role: string,
  applicantType?: string,
  validateFormIncludingAllJointHoldersInInvestorLogin?: boolean
) => {
  const {
    riskprofiles = [],
    isRiskProfileToBeFilledByInvestor = true,
    riskProfileDeclaration = false,
  } = application || {};
  try {
    const schemaData = {
      isRiskProfileToBeFilledByInvestor: isRiskProfileToBeFilledByInvestor || false,
      riskprofiles: riskProfileDataMaster
        ?.map((mdmsProfile) => {
          const existingRiskProfiles = riskprofiles?.filter(
            (risk_profiles) => risk_profiles.question === mdmsProfile.key
          );
          return existingRiskProfiles.length
            ? existingRiskProfiles?.map((existingProfiles) => {
                if (
                  existingProfiles.question === mdmsProfile.key &&
                  mdmsProfile.isMultiselect === 'true'
                ) {
                  const getInvestment = handleIntialSelect(riskprofiles, mdmsProfile.key);
                  const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                  let getOtherValue = '';
                  const updateOptions = getInvestment.map((ans) => {
                    if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                      getOtherValue = ans.split('_')[0];
                      return `others_${ans.split('_')[1]}`;
                    }
                    return ans;
                  });
                  return {
                    ...existingProfiles,
                    ...mdmsProfile,
                    values: mdmsProfile.values?.map((value) => {
                      const scoreUpdate = updateOptions
                        .find((investment) => investment?.split('_')[0] === value.key)
                        ?.split('_')[1];

                      return {
                        ...value,
                        score: scoreUpdate ? Number(scoreUpdate) : value.score,
                      };
                    }),
                    InvestmentGoal: updateOptions || [],
                    scoreCal: Number(existingProfiles.scoreText) || 0,
                    otherValue: getOtherValue,
                    answer: getOtherValue
                      ? updateOptions?.toString()?.replace(/,/g, '*')
                      : existingProfiles.answer,
                  };
                } else {
                  const checkAnswer =
                    existingProfiles.question === mdmsProfile.key &&
                    mdmsProfile.isMultiselect === 'false' &&
                    mdmsProfile.values?.map((value) => value.key).includes(existingProfiles.answer);

                  return {
                    ...existingProfiles,
                    ...mdmsProfile,
                    values: mdmsProfile.values?.map((value) => {
                      if (existingProfiles.answer === value.key) {
                        return {
                          ...value,
                          score: Number(existingProfiles.scoreText),
                        };
                      }
                      return value;
                    }),
                    otherValue:
                      checkAnswer || mdmsProfile.questionType === 'fieldForEnterNumber'
                        ? ''
                        : existingProfiles.answer,
                    answer:
                      checkAnswer || mdmsProfile.questionType === 'fieldForEnterNumber'
                        ? existingProfiles.answer
                        : 'others',
                  };
                }
              })
            : [{ ...RiskProfileObj, question: mdmsProfile.key, ...mdmsProfile }];
        })
        .flat(),
    };

    const checkAllQuestionsAnswered = schemaData.riskprofiles
      ?.map((profile) => profile.answer !== '' && profile.required === 'true')
      ?.every((_profile) => _profile);
    if (APMIFlow && !isRiskProfileToBeFilledByInvestor && !applicantType) {
      throw new RiskProfileErrors(
        `In Risk Profile, ${sectionFilledByInvestor} is required, if it is selected then please do update that section using save & proceed`
      );
    }
    if (
      (!isRiskProfileToBeFilledByInvestor ||
        (isRiskProfileToBeFilledByInvestor &&
          ((applicantType && fieldValidateForInvestor(applicantType, 1, false)) ||
            validateFormIncludingAllJointHoldersInInvestorLogin))) &&
      !checkAllQuestionsAnswered
    ) {
      throw new RiskProfileErrors('In Risk Profile Please fill all the required(*) fields');
    }
    const findIndicativeAnswer = schemaData.riskprofiles?.find(
      (risk) => risk.key === 'indicative%ofTotalInvestment'
    );
    if (Number(findIndicativeAnswer?.answer) > 100) {
      throw new RiskProfileErrors(
        'Maximum percentage for Indicative % of Total Investment Portfolio proposed is 100%'
      );
    }
    if (Number(findIndicativeAnswer?.answer) < 0) {
      throw new RiskProfileErrors(
        'Minimum percentage for Indicative % of Total Investment Portfolio proposed is 0%'
      );
    }
    schemaData.riskprofiles?.map((risk: updatedRiskProfile) => {
      if (
        !isRiskProfileToBeFilledByInvestor ||
        (isRiskProfileToBeFilledByInvestor &&
          ((applicantType && fieldValidateForInvestor(applicantType, 1, false)) ||
            validateFormIncludingAllJointHoldersInInvestorLogin))
      ) {
        const questionForDisplay = riskProfileDataMaster
          ?.map((riskProfile) => {
            if (riskProfile.key === risk.question) {
              return riskProfile.displayText;
            }
            return;
          })
          ?.filter((ele) => ele)
          ?.toString();
        if (
          (risk.answer === 'others' ||
            (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
          !risk.otherValue &&
          risk.required === 'true'
        ) {
          throw risk.question === 'investmentOrTradingExperiance'
            ? `Investment/Trading experience shouldn't be zero/empty years`
            : `Please specify others in ${questionForDisplay}`;
        }
        if (
          risk.questionType === 'radio' &&
          risk.isMultiselect === 'false' &&
          !risk?.values?.map((_r) => _r.key).includes(risk.answer)
        ) {
          throw `Invalid ${questionForDisplay}`;
        }
        if (risk.key === 'TimePeriod' && !risk.answer.match(defaultRegexWithSingleLine)) {
          throw `Invalid ${questionForDisplay}`;
        }
        if (
          risk.questionType === 'radio' &&
          risk.isMultiselect === 'true' &&
          risk?.InvestmentGoal?.map((_I: any) =>
            risk?.values?.map((_r) => _r.key).includes(_I)
          ).includes(false)
        ) {
          throw `Invalid ${questionForDisplay}`;
        }
      }
    });
    if (!riskProfileDeclaration && application && checkIfApplicationIsNonIndividual(application)) {
      throw new RiskProfileErrors('In Risk Profile Declaration is required');
    }
    // await validateYupSchema(schemaData, strategyDeclarationScheme, true, schemaData);
  } catch (e) {
    if (e instanceof RiskProfileErrors) {
      throw e;
    }
    if (typeof e !== 'string') {
      throw e;
    }
  }
};

// export const validateStrategyDetails = async (
//   application: ApplicationProps | null,
//   strategyRes: StrategyType[],
//   responseFeeSlabs: FeeSlabsType[],
//   role: string
// ) => {
//   const { applicationstrategydetails = [] } = application || {};
//   try {
//     const schemaData = {
//       totalStrategyInvestmentAmount: application?.totalStrategyInvestmentAmount || null,
//       ownBorrowedFunds: application?.ownBorrowedFunds || '',
//       cheque: application?.modeOfPayment ? application.modeOfPayment.includes('cheque') : false,
//       rtgs: application?.modeOfPayment ? application.modeOfPayment.includes('rtgs') : false,
//       applicationstrategydetails: strategyRes
//         ?.map((strategy) => {
//           const existingStrategyDetails = applicationstrategydetails?.filter(
//             (strategyDetails) => strategyDetails.strategyId === strategy.id
//           );
//           return existingStrategyDetails.length
//             ? existingStrategyDetails?.map((existingStrategy) => {
//                 return {
//                   ...existingStrategy,
//                   maxFee: strategy.maxFee,
//                   minFee: strategy.minFee,
//                   performanceMaxFee: strategy.maxPerformanceFee,
//                   performanceMinFee: strategy.minPerformanceFee,
//                   exitMaxFee: strategy.maxExitFee,
//                   exitMinFee: strategy.minExitFee,
//                 };
//               })
//             : [
//                 {
//                   ...newStrategy,
//                   strategyName: strategy.strategyName,
//                   strategyId: strategy.id,
//                   maxFee: strategy.maxFee,
//                   minFee: strategy.minFee,
//                   performanceMaxFee: strategy.maxPerformanceFee,
//                   performanceMinFee: strategy.minPerformanceFee,
//                   exitMaxFee: strategy.maxExitFee,
//                   exitMinFee: strategy.minExitFee,
//                 },
//               ];
//         })
//         .flat(),
//     };
//     if (!applicationstrategydetails.length) {
//       throw new StrategyErrors('Please Select atleast One Strategy In Strategy Details');
//     }
//     await validateYupSchema(schemaData, strategyDeclarationScheme, true, schemaData);
//   } catch (error) {
//     if (error instanceof StrategyErrors) {
//       throw error;
//     }
//     throw role === USER_ROLES.AMCAPPROVER && !CHECKER_EDIT_ALL_SECTIONS
//       ? new StrategyErrors(`In Strategy details, the required fields are not filled.`)
//       : `Strategy details`;
//   }
// };

export const validateStrategyDetails = async (
  application: ApplicationProps | null,
  strategyRes: Partial<StrategyType>[],
  responseFeeSlab: Partial<FeeSlabsType>[],
  role: string
) => {
  const {
    applicationstrategydetails,
    liquidApplicationFeeSlab,
    applicationfeeslab,
    investmentAmount,
  } = application || {};
  try {
    if (applicationstrategydetails?.length) {
      for (const applicationStrategyDetail of applicationstrategydetails) {
        strategyRes = applicationStrategyDetail?.strategyId
          ? !strategyRes
              .map((strategy) => strategy?.id)
              .includes(applicationStrategyDetail?.strategyId)
            ? strategyRes.concat({
                ...applicationStrategyDetail,
                id: applicationStrategyDetail?.strategyId,
              })
            : strategyRes
          : strategyRes;
      }
    }

    responseFeeSlab = applicationfeeslab?.feeSlabId
      ? !responseFeeSlab.map((fee) => fee?.id).includes(applicationfeeslab?.feeSlabId)
        ? responseFeeSlab.concat({ ...applicationfeeslab, id: applicationfeeslab?.feeSlabId })
        : responseFeeSlab
      : responseFeeSlab;
    const transformedDataArray: Partial<StrategyType>[] = responseFeeSlab.filter((responseData) => {
      return !(applicationstrategydetails || []).some(
        (appData) => appData.strategyId === responseData.id
      );
    });

    const result = [
      ...(applicationstrategydetails || []).map((appData) => {
        return {
          ...appData,
        };
      }),
      ...transformedDataArray.map((val) => {
        const { id, ...rest } = val;
        return { ...rest, isActive: false, strategyId: id };
      }),
    ] as Partial<StrategyType>[];
    const schemaData = {
      investmentAmount,
      liquidFeeSlabDetails: responseFeeSlab
        .filter((val) => val.feeType === Liquid_FeeType)
        .map((fee) => {
          const { id, ...rest } = fee;
          return {
            ...rest,
            feeSlabId: id,
            isActive: fee.id === liquidApplicationFeeSlab?.feeSlabId,
            hurdlerates: fee.hurdlerates?.length ? fee.hurdlerates : [],
          };
        }),
      feeSlabdetails: responseFeeSlab
        .filter((val) => val.feeType !== Liquid_FeeType)
        .filter((val1) => val1.feeType === applicationfeeslab?.feeType)
        .map((fee) => {
          const { id, ...rest } = fee;
          return {
            ...rest,
            feeSlabId: id,
            isActive: fee.id === applicationfeeslab?.feeSlabId,
            hurdlerates: fee.hurdlerates?.length ? fee.hurdlerates : [],
          };
        }),

      applicationstrategydetails: result
        ?.map((strategy) => {
          return {
            ...strategy,
            strategyName: strategy.strategyName,
            isActive: strategy.isActive as boolean,
            feeType: containsLiquid(strategy.strategyName || '')
              ? liquidApplicationFeeSlab?.feeType || null
              : applicationfeeslab?.feeType || null,
          };
        })
        .flat(),
    };

    const liquidStrategy = schemaData.applicationstrategydetails.filter(
      (val) => val.feeType === Liquid_FeeType && val.isActive
    );
    const equityStrategy = schemaData.applicationstrategydetails.filter(
      (val) => val.feeType !== Liquid_FeeType && val.isActive
    );

    if (!checkStrategyBoxSelected(schemaData.applicationstrategydetails)) {
      throw new StrategyErrors('Please select one strategy');
    }
    if (liquidStrategy.length > 0 && !checkStrategyFeeTypeSelected(liquidStrategy)) {
      throw new StrategyErrors('Please select one fee type for liquid strategy');
    }
    if (equityStrategy.length > 0 && !checkStrategyFeeTypeSelected(equityStrategy)) {
      throw new StrategyErrors('Please select one fee type for equity strategy');
    }

    if (
      equityStrategy?.length > 0 &&
      !checkStrategyFeeSlabSelected(schemaData.applicationstrategydetails, [
        { ...applicationfeeslab },
      ] as Partial<FeeSlabsType>[])
    ) {
      throw new StrategyErrors('Please select one fee slab for equity strategy');
    }

    if (
      liquidStrategy?.length > 0 &&
      !checkStrategyFeeSlabSelected(schemaData.applicationstrategydetails, [
        { ...liquidApplicationFeeSlab },
      ] as Partial<FeeSlabsType>[])
    ) {
      throw new StrategyErrors('Please select one fee slab for liquid strategy');
    }

    await validateYupSchema(schemaData, StrategyDetailSchema, true, schemaData);
  } catch (error) {
    if (error instanceof StrategyErrors) {
      throw error;
    }
    throw role === USER_ROLES.AMCAPPROVER && !CHECKER_EDIT_ALL_SECTIONS
      ? new StrategyErrors(`In strategy details, the required fields are not filled.`)
      : `Strategy details`;
  }
};

export const validateDisclosureOfInterest = async (
  application: ApplicationProps | null,
  applicantType: string,
  validateFormIncludingAllJointHoldersInInvestorLogin: boolean,
  mdmsCompanyList: mdmsCompaniesList[]
) => {
  try {
    const schemaData = {
      isDisclosureOfInterestToBeFilledByInvestor:
        application?.isDisclosureOfInterestToBeFilledByInvestor || false,
      interestedCompaniesCheck: application?.interestedCompaniesCheck || '',
      interestedcompanies: application?.interestedcompanies.length
        ? application.interestedcompanies?.map((interestedCompanies, index) => {
            return { ...interestedCompanies, sno: index };
          })
        : [],
    };
    if (APMIFlow && !application?.isDisclosureOfInterestToBeFilledByInvestor && !applicantType) {
      throw new DisclosureOfInterestErrors(
        `In Disclosure Of Interest, ${sectionFilledByInvestor} is required, if it is selected then please do update that section using save & proceed`
      );
    }
    if (YesNoMaster[schemaData.interestedCompaniesCheck] === YesNoMaster.no) {
      return;
    }
    await validateYupSchema(
      schemaData,
      disclosureOfInterestSchema(
        applicantType,
        mdmsCompanyList,
        validateFormIncludingAllJointHoldersInInvestorLogin
      ),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof DisclosureOfInterestErrors) {
      throw error;
    }
    throw `Disclosure Of Interest`;
  }
};

export const validateDisclusionOfExclusion = async (
  application: ApplicationProps | null,
  applicantType: string,
  validateFormIncludingAllJointHoldersInInvestorLogin: boolean,
  mdmsCompanyList: mdmsCompaniesList[]
) => {
  try {
    const schemaData = {
      isDisclosureOfExclusionsToBeFilledByInvestor:
        application?.isDisclosureOfExclusionsToBeFilledByInvestor || false,
      excludedCompaniesCheck: application?.excludedCompaniesCheck || '',
      excludedcompanies: application?.excludedcompanies.length
        ? application.excludedcompanies?.map((excludedCompanies, index) => {
            return { ...excludedCompanies, sno: index };
          })
        : [],
    };
    if (APMIFlow && !application?.isDisclosureOfExclusionsToBeFilledByInvestor && !applicantType) {
      throw new DisclosureOfExclusionsErrors(
        `In Disclosure Of Exclusions, ${sectionFilledByInvestor} is required, if it is selected then please do update that section using save & proceed`
      );
    }
    if (YesNoMaster[schemaData.excludedCompaniesCheck] === YesNoMaster.no) {
      return;
    }
    await validateYupSchema(
      schemaData,
      disclosureOfExcludedSchema(
        applicantType,
        mdmsCompanyList,
        validateFormIncludingAllJointHoldersInInvestorLogin
      ),
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof DisclosureOfExclusionsErrors) {
      throw error;
    }
    throw `Disclosure Of Exclusions`;
  }
};

export const validateRelatedParty = async (application: ApplicationProps | null) => {
  try {
    const {
      relatedpartyconsents = [],
      relatedPartyLimit = '',
      relatedPartyWaiver = '',
    } = application || {};

    if (!relatedPartyLimit || !relatedPartyWaiver) {
      throw new RelatedPartyConsentErrors(
        'Please choose consent or dissent in Related Party Consent'
      );
    }
    if (!relatedPartyConsent.includes(relatedPartyLimit)) {
      throw 'Invalid Limits On investment';
    }
    if (!relatedPartyConsent.includes(relatedPartyWaiver)) {
      throw 'Invalid Waiver from rebalancing of portfolio on passive breach of investment limits';
    }
    const schemaData = {
      relatedpartyconsents: relatedpartyconsents.length ? relatedpartyconsents : relatedDataArr,
    };
    relatedPartyLimit === 'consent' &&
      (await validateYupSchema(schemaData, relatedPartyConsentSchema, true, schemaData));
  } catch (error) {
    if (error instanceof RelatedPartyConsentErrors) {
      throw error;
    }
    throw `Related Party Consent`;
  }
};

export const validateDocuments = async (
  applicants: Partial<Applicant>[],
  hasPOA: boolean,
  documentsData: Document,
  Nominees: Partial<NomineeType>[],
  applicantType: string,
  validateFormIncludingAllJointHoldersInInvestorLogin: boolean,
  banks: Partial<Bank>[],
  validateInvestorSignatureOnly: boolean
) => {
  const schemaData = {
    applicants: applicants.map((applicant, applicant_index) => {
      let docData = '';
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
      }
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
      }
      return {
        documents:
          typeof documentsData !== 'undefined'
            ? ((documentsData as Document)[docData] || [])
                .filter((doc) => {
                  if (
                    hasPOA &&
                    applicant.applicant_type !== '1' &&
                    doc.documentType === 'poaNotarized'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .filter((document) => {
                  if (applicant.amlCheck && document.documentType === 'compliance_document') {
                    return;
                  }
                  return document;
                })
                .filter((ele) => ele)
                .filter((doc) => {
                  if (
                    !applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .map((doc) => {
                  if (
                    applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  return doc;
                })
                // .map((updateRequired) => {
                //   if (
                //     updateRequired.documentType === 'investorSignature' &&
                //     !fieldValidateForInvestor(applicantType, applicant_index + 1, false) &&
                //     !validateFormIncludingAllJointHoldersInInvestorLogin
                //   ) {
                //     return {
                //       ...updateRequired,
                //       required: 'false',
                //     };
                //   }
                //   return updateRequired;
                // })
                .filter((ele) => ele)
                .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                  const { documentType, documentName, multipleFiles, required, options } = doc;
                  const { documents: existingDocuments = [] } = applicant || {};
                  const docsOfCurrentDocType = existingDocuments
                    .filter((doc) => doc.documentType === documentType)
                    .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                  const defaultDocObj = {
                    documentType,
                    documentName,
                    documentNumber: '',
                    documentExpiryDate: null,
                    required,
                    options,
                    uniqueKey: (applicant_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      '0') as string,
                    ...document_object,
                  };
                  const existingDocObj = docsOfCurrentDocType?.map((doc, ind) => {
                    const {
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      documentNumber = '',
                      documentExpiryDate = '',
                      isActive = true,
                      loading = false,
                      file = undefined,
                    } = doc;
                    return {
                      id: doc.id,
                      applicantId: doc.applicantId,
                      documentType,
                      documentName,
                      documentNumber,
                      documentExpiryDate,
                      documentId,
                      loading,
                      isActive,
                      file,
                      options,
                      required:
                        ind === 1 &&
                        !documentNameValidationCheckForMultipleDocumentsMandatory(documentName)
                          ? 'false'
                          : required,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        ind.toString()) as string,
                    };
                  });
                  return {
                    documentType: documentType,
                    documentName: documentName,
                    documentsList: docsOfCurrentDocType.length
                      ? multipleFiles === 'true' && existingDocObj?.length === 1
                        ? [
                            ...existingDocObj,
                            {
                              ...defaultDocObj,
                              documentName: existingDocObj[existingDocObj.length - 1].documentName,
                              required: documentNameValidationCheckForMultipleDocumentsMandatory(
                                existingDocObj[existingDocObj.length - 1].documentName
                              )
                                ? required
                                : 'false',
                              uniqueKey: (applicant_index.toString() +
                                '-' +
                                index.toString() +
                                '-' +
                                '1') as string,
                            },
                          ]
                        : existingDocObj
                      : multipleFiles === 'true'
                      ? [
                          defaultDocObj,
                          {
                            ...defaultDocObj,
                            required: 'false',
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '1') as string,
                          },
                        ]
                      : [defaultDocObj],
                    required,
                    multipleFiles,
                    options,
                  };
                })
            : [],
      };
    }),
    nominees: Nominees.map((nominee, nominee_index) => {
      return {
        nomineedocuments:
          typeof documentsData !== 'undefined'
            ? (isMinor(nominee.dateOfBirth || '')
                ? [
                    ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                      []),
                    ...((documentsData as Document)[
                      APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                    ] || []),
                  ].map((doc) => {
                    if (doc.documentType === 'nomineeIdProof') {
                      return { ...doc, required: 'false' };
                    }
                    if (doc.documentType === 'guardianIdProof') {
                      return { ...doc, required: 'true' };
                    }
                    return doc;
                  })
                : (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
              ).map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { nomineedocuments: existingDocuments = [] } = nominee || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                const defaultDocObj = {
                  documentType,
                  documentName,
                  required,
                  options,
                  uniqueKey: (nominee_index.toString() +
                    '-' +
                    index.toString() +
                    '-' +
                    '0') as string,
                  ...document_object,
                };
                const existingDocObj = docsOfCurrentDocType.map((doc, ind) => {
                  const {
                    documentName = '',
                    documentType = '',
                    documentId = '',
                    isActive = true,
                    file = undefined,
                  } = doc;
                  return {
                    id: doc.id,
                    nomineeId: doc.nomineeId,
                    documentType,
                    documentName,
                    documentId,
                    isActive,
                    file,
                    options,
                    required:
                      ind === 1 &&
                      !documentNameValidationCheckForMultipleDocumentsMandatory(documentName)
                        ? 'false'
                        : required,
                    uniqueKey: (nominee_index.toString() +
                      '-' +
                      index.toString() +
                      '-' +
                      ind.toString()) as string,
                  };
                });
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? multipleFiles === 'true' && existingDocObj?.length === 1
                      ? [
                          ...existingDocObj,
                          {
                            ...defaultDocObj,
                            documentName: existingDocObj[existingDocObj.length - 1].documentName,
                            required: documentNameValidationCheckForMultipleDocumentsMandatory(
                              existingDocObj[existingDocObj.length - 1].documentName
                            )
                              ? required
                              : 'false',
                            uniqueKey: (nominee_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '1') as string,
                          },
                        ]
                      : existingDocObj
                    : multipleFiles === 'true'
                    ? [
                        defaultDocObj,
                        {
                          ...defaultDocObj,
                          required: 'false',
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '1') as string,
                        },
                      ]
                    : [defaultDocObj],
                  required,
                  multipleFiles,
                  options,
                };
              })
            : [],
      };
    }),
    banks: banks,
  };
  try {
    await validateYupSchema(
      schemaData,
      validateInvestorSignatureOnly ? documentDetailsSchemaForInvestorLogin : documentDetailsSchema,
      true,
      schemaData
    );
  } catch (error) {
    throw `Document details`;
  }
};

export const isFormValidForSubmission = async (
  application: ApplicationProps | null,
  validateDoc = true,
  verifyPennydrop = true,
  nationalitiesMdmsMasters = {},
  validationOrConfigChecks: ValidationOrConfigMaster,
  documentsResponse = {},
  role = '',
  riskProfileDataMaster: RiskProfileMasterType[],
  strategyRes: Partial<StrategyType>[],
  responseFeeSlabs: Partial<FeeSlabsType>[],
  referenceDetail: getDetailsByRefrenceType,
  mdmsCompanyList: mdmsCompaniesList[],
  validateFormIncludingAllJointHoldersInInvestorLogin = false
) => {
  const {
    applicants = [],
    nominees = [],
    doNotWishToNominate = false,
    banks = [],
    hasPOA = true,
    applicationType = '',
    modeOfHolding = '',
    createdAt = '',
  } = application || {};
  const { micrValidation = '' } = validationOrConfigChecks || {};
  try {
    await validateAdditionalDetails(applicants, role);
    await validateKYCDetails(application, role);
    await validateFATCA(applicants, role, nationalitiesMdmsMasters as nationaliyType);
    await validateNomineeDetails(
      nominees,
      doNotWishToNominate,
      applicants,
      modeOfHolding,
      nationalitiesMdmsMasters as nationaliyType
    );
    await validateBankDetails(
      banks,
      verifyPennydrop,
      applicationType,
      applicants,
      createdAt,
      micrValidation
    );
    await validateRiskProfile(
      application,
      riskProfileDataMaster,
      role,
      referenceDetail.applicant_type,
      validateFormIncludingAllJointHoldersInInvestorLogin
    );
    await validateStrategyDetails(application, strategyRes, responseFeeSlabs, role);
    await validateDisclosureOfInterest(
      application,
      referenceDetail.applicant_type,
      validateFormIncludingAllJointHoldersInInvestorLogin,
      mdmsCompanyList
    );
    await validateDisclusionOfExclusion(
      application,
      referenceDetail.applicant_type,
      validateFormIncludingAllJointHoldersInInvestorLogin,
      mdmsCompanyList
    );
    await validateRelatedParty(application);
    validateDoc &&
      Object.keys(documentsResponse).length !== 0 &&
      (await validateDocuments(
        applicants,
        hasPOA,
        documentsResponse as Document,
        nominees,
        referenceDetail.applicant_type,
        validateFormIncludingAllJointHoldersInInvestorLogin,
        banks,
        false
      ));
  } catch (error) {
    if (error instanceof RiskProfileErrors) {
      throw (error as RiskProfileErrors).message;
    }
    if (error instanceof FatcaErrors) {
      throw (error as FatcaErrors).message;
    }
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    if (error instanceof StrategyErrors) {
      throw (error as StrategyErrors).message;
    }
    if (error instanceof DisclosureOfInterestErrors) {
      throw (error as DisclosureOfInterestErrors).message;
    }
    if (error instanceof DisclosureOfExclusionsErrors) {
      throw (error as DisclosureOfExclusionsErrors).message;
    }
    if (error instanceof RelatedPartyConsentErrors) {
      throw (error as RelatedPartyConsentErrors).message;
    }
    throw `In ${error}, the required fields are not filled.`;
  }
};
export const pennyDropVerification =
  (body: Bank) =>
  async (dispatch: any): Promise<Bank> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/pennydropverification`,
        method: 'POST',
        types: [PENNY_DROP_VERIFICATION_SUCCESS, PENNY_DROP_VERIFICATION],
        body,
      },
    });
  };

export const getDocuments =
  () =>
  async (dispatch: any): Promise<Document> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/required_document_master.json',
        method: 'GET',
        types: [GET_Documents_SUCCESS, GET_Documents],
      },
    });
  };

export const getUboTypes =
  () =>
  async (dispatch: any): Promise<uboTypes> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/uboTypeMaster.json',
        method: 'GET',
        types: [GET_Ubo_SUCCESS, GET_Ubo],
      },
    });
  };

export const FetchData =
  (body: FetchUBORequestBody) =>
  async (dispatch: any): Promise<ubo> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kradetails`,
        method: 'POST',
        types: [UBO_LISTING_SUCCESS, UBO_LISTING],
        body,
      },
    });
  };

export const FatcaMdmsData =
  () =>
  async (dispatch: any): Promise<FatcaMdms> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/fatca_data.json',
        method: 'GET',
        types: [GET_Fatca_SUCCESS, GET_Fatca],
      },
    });
  };

export const validateNonIndividualContributorDetails = async (applicants: Partial<Applicant>[]) => {
  const schemaData = {
    applicants: applicants,
  };
  try {
    await validateYupSchema(schemaData, NonIndividualContributorValidationSchema, true, schemaData);
  } catch (error) {
    throw `Contributor details`;
  }
};
export const validateNonIndividualContactDetails = async (
  applicants: Partial<Applicant>[],
  countryDropdown: string[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      const correspondence = getAddressData('correspondence', applicant.addresses);
      const permanent = getAddressData('permanent', applicant.addresses);
      const defaultPayload = {
        contactperson: applicant.contactperson
          ? {
              ...applicant.contactperson,
              countryNameAndCode: applicant.contactperson.countryNameAndCode
                ? applicant.contactperson.countryNameAndCode
                : 'India: +91',
              country: applicant.contactperson.country
                ? applicant.contactperson.country.toUpperCase()
                : 'INDIA',
            }
          : contactPersonObject,
        address: {
          correspondence,
        },
      };
      const permanentAddressPayload = correspondence.permanentAddressSameAsCorresponding
        ? defaultPayload
        : {
            ...defaultPayload,
            address: {
              ...defaultPayload.address,
              permanent: {
                ...permanent,
                country: permanent.country ? permanent.country.toUpperCase() : 'INDIA',
              },
              correspondence: {
                ...correspondence,
                country: correspondence.country ? correspondence.country.toUpperCase() : 'INDIA',
              },
            },
          };
      return permanentAddressPayload;
    }),
    countryDropdown: countryDropdown,
  };
  try {
    await validateYupSchema(schemaData, nonIndividualContactDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Contact details`;
  }
};

export const validateNonIndividualDocumentDetails = async (
  applicants: Partial<Applicant>[],
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  banks: Partial<Bank>[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant, applicant_index) => {
      return {
        documents: documentsData
          .filter((document) => {
            if (applicant.amlCheck && document.documentType === 'compliance_document') {
              return;
            }
            return document;
          })
          .filter((ele) => ele)
          //.map((doc) => ({ ...doc, required: 'true' }))
          .filter((doc) => {
            if (
              !applicant.correspondenceAddressEdited &&
              doc.documentType === 'correspondenceAddress'
            ) {
              return;
            }
            return doc;
          })
          .filter((ele) => ele)
          .map((doc) => {
            if (
              applicant.correspondenceAddressEdited &&
              doc.documentType === 'correspondenceAddress'
            ) {
              return { ...doc, required: 'true' };
            }
            return doc;
          })
          .map((doc, index) => {
            const { documentType, documentName, multipleFiles, required, options } = doc;
            const { documents: existingDocuments = [] } = applicant || {};
            const docsOfCurrentDocType = existingDocuments
              .filter((doc) => doc.documentType === documentType)
              .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
            return {
              documentType: documentType,
              documentName: documentName,
              documentsList: docsOfCurrentDocType.length
                ? docsOfCurrentDocType.map((doc, ind) => {
                    const {
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      isActive = true,
                      file = undefined,
                    } = doc;
                    return {
                      documentType,
                      documentName,
                      documentId,
                      isActive,
                      file,
                      options,
                      required,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        ind.toString()) as string,
                    };
                  })
                : [
                    {
                      documentType,
                      documentName,
                      required,
                      options,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        '0') as string,
                      ...document_object,
                    },
                  ],
              required,
              multipleFiles,
              options,
            };
          }),
      };
    }),
    banks: banks,
  };
  try {
    await validateYupSchema(schemaData, nonIndividualDocumentDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Document details`;
  }
};
const fieldsTocheckForAuthorisedSignatories = [
  {
    key: 'name',
    label: 'Name',
    validateMulitple: false,
  },
  {
    key: 'pan',
    label: 'PAN',
    validateMulitple: true,
    regex: individualPanRegex,
    regexMsg: 'Only Individual PANs are allowed',
  },
  { key: 'mobile', label: 'Mobile Number', validateMulitple: true },
  {
    key: 'email',
    label: 'Email ID',
    validateMulitple: true,
    regex: emailRegex,
  },
  {
    key: 'designation',
    label: 'Designation',
    validateMulitple: false,
  },
];

export const validateNonIndividualAuthorisedSignatories = async (groups: Groups[]) => {
  try {
    if (!groups || !groups.length) {
      throw new AuthorisedErrors('Please add Authorized Signatories');
    }

    groups.map((group) => {
      const getActiveSignatories = group.groupsignatories.filter((item) => item.isActive);
      const getCanEsignCount = getActiveSignatories.filter((item) => item.canEsign);
      if (!getActiveSignatories.length) {
        throw new AuthorisedErrors('Please add Authorized Signatories');
      }
      if (!getCanEsignCount.length) {
        throw new AuthorisedErrors('Please Select a member for E-Sign in Authorized Signatories');
      }
      if (getCanEsignCount.length < group.threshold) {
        throw new AuthorisedErrors(
          'Authorized Signatories that can esign can not be less than the Total No. of required Authorized Signatories'
        );
      }
      getActiveSignatories.forEach((signatory, index) => {
        fieldsTocheckForAuthorisedSignatories.forEach((field) => {
          if (!signatory[field.key as keyof GroupSignatories] as unknown as string) {
            throw new AuthorisedErrors(`${field.label} is required for signatory ${index + 1}`);
          }
          if (
            field.regex &&
            !field.regex.test(signatory[field.key as keyof GroupSignatories] as unknown as string)
          ) {
            throw new AuthorisedErrors(
              field.regexMsg
                ? `${field.regexMsg} for signatory ${index + 1}`
                : `Please enter valid ${field.label} for signatory ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = getActiveSignatories.filter(
              (vsignatory) =>
                (vsignatory[field.key as keyof GroupSignatories] as unknown as string) ===
                (signatory[field.key as keyof GroupSignatories] as unknown as string)
            );
            if (values.length > 1) {
              throw new AuthorisedErrors(
                `There is already same ${field.label} for an Authorized Signatory associated with this application`
              );
            }
          }
        });
      });
      return group;
    });
  } catch (error) {
    if (error instanceof AuthorisedErrors) {
      throw error;
    }
  }
};

const fieldsTocheckForUbos = [
  {
    key: 'panNumber',
    label: 'Taxpayer ID Number/PAN/Equivalent ID Number',
    validateMulitple: true,
    required: true,
  },
  {
    key: 'dob',
    label: 'Date of Birth',
    validateMulitple: false,
    required: true,
  },
  { key: 'name', label: 'Name of UBO', validateMulitple: false, required: true },
  {
    key: 'identificationType',
    label: 'Identification Type',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'percentageOfBeneficialInterest',
    label: 'Percentage of beneficial interest',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'countryOfTaxResidency',
    label: 'Country of Tax Residency',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'cpUboCode',
    label: 'CP/UBO Code',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'placeAndCountryOfBirth',
    label: 'Place & Country of Birth',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'occupation',
    label: 'Occupation',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'gender',
    label: 'Gender',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'nationality',
    label: 'Nationality',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'fatherName',
    label: 'Father Name',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'ckycNumber',
    label: 'CKYC Number',
    validateMulitple: true,
    required: false,
  },
  {
    key: 'address2',
    label: 'Address 1',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'address3',
    label: 'Address 2',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'pincode',
    label: 'Pincode',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'city',
    label: 'City',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'state',
    label: 'State',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'country',
    label: 'Country',
    validateMulitple: false,
    required: true,
  },
];
const validateNonIndividualUbo = async (
  applicants: Partial<Applicant>[],
  ubo_declaration_type: string,
  ubo_declaration_value: string,
  ubos: ubo[],
  nationalityDropdown: string[],
  countryDropdown: string[]
) => {
  try {
    const applicantsCkycNumbers = applicants?.map((applicant) => applicant.ckycNo);
    if (!ubo_declaration_type) {
      throw new UboErrors('Please Select Declaration Type in Declaration of Ubo');
    }
    if (!ubo_declaration_value) {
      throw new UboErrors('Please Select Declaration value in Declaration of Ubo');
    }
    if (ubo_declaration_type === 'none' && !ubos.filter((_item) => _item.isActive).length) {
      throw new UboErrors('Please Add Ultimate Benificiary Owners(UBO)');
    }
    if (ubos.filter((_item) => _item.isActive).length) {
      const activeUbos = ubos.filter((_item) => _item.isActive);
      activeUbos.map((ubo) => {
        if (ubo.ckycNumber && applicantsCkycNumbers.includes(ubo.ckycNumber)) {
          throw new UboErrors(
            `CKYC Number of ${ubo.name} should not be same as CKYC Number exist in Applicant(s) Details`
          );
        }
        if (!nationalityDropdown.includes(ubo.nationality || '')) {
          throw new UboErrors(`Invalid Nationality for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.country || '')) {
          throw new UboErrors(`Invalid Country for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.countryOfTaxResidency || '')) {
          throw new UboErrors(
            `Invalid Country of Tax Residency for ${ubo.name} in Declaration of Ubo`
          );
        }
        if (!Object.keys(occupationDetailsMasters).includes(ubo.occupation || '')) {
          throw new UboErrors(`Invalid Occupation for ${ubo.name} in Declaration of Ubo`);
        }
      });
      percentageError(ubos, true);
    }
    ubos
      .filter((_item) => _item.isActive)
      .forEach((ubo, index) => {
        fieldsTocheckForUbos.forEach((field) => {
          if ((!ubo[field.key as keyof ubo] as unknown as string) && field.required) {
            throw new UboErrors(`${field.label} is required for ubo ${index + 1}`);
          }
          if (
            field.key === 'dob' &&
            (ubo[field.key as keyof ubo] as unknown as string) &&
            isMinor(field.key)
          ) {
            throw new UboErrors(`Age should be greater than 18 for ubo ${index + 1}`);
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) <= 0
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest should be greater 0 for ubo ${index + 1}`
            );
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) > 100
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest must not exceed 100% for ubo ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = ubos.filter(
              (_ubo) =>
                (_ubo[field.key as keyof ubo] as unknown as string) ===
                  (ubo[field.key as keyof ubo] as unknown as string) &&
                (ubo[field.key as keyof ubo] as unknown as string) &&
                (_ubo[field.key as keyof ubo] as unknown as string)
            );
            if (values.length > 1) {
              throw new UboErrors(
                `There is already same ${field.label} for an Ubos associated with this application`
              );
            }
          }
        });
      });
  } catch (e) {
    if (e instanceof UboErrors) {
      throw e;
    }
  }
};

export const validateNonIndividualFatca = async (
  applicants: Partial<Applicant>[],
  fatcaMasterData: nonIndividualMdmsQuestionsFatca,
  mdmsCountriesList: mdmsCountriesList[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      const { fatcadetail: existingFatcaDetails = null } = applicant || {};
      return {
        fatcadetail: existingFatcaDetails
          ? AssignAnsAndVisibilityForInitialFatca(fatcaMasterData, existingFatcaDetails)
          : fatcaMasterData,
      };
    }),
  };
  schemaData?.applicants?.forEach((applicant: any) => {
    applicant?.fatcadetail?.forEach((fatca: any) => {
      validationPopUp(fatca, applicants, mdmsCountriesList);
    });
  });
  try {
    await validateYupSchema(schemaData, nonIndividualFatcaSchema, true, schemaData);
  } catch (error) {
    if (error instanceof fatcaErrors) {
      throw error;
    }
    throw `FATCA details`;
  }
};

export const nonIndividualFormValidForSubmission = async (
  application: ApplicationProps | null,
  validationOrConfigChecks: ValidationOrConfigMaster,
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  mdmsCountriesList: mdmsCountriesList[],
  verifyPennydrop = true,
  role = '',
  riskProfileDataMaster: RiskProfileMasterType[],
  strategyRes: Partial<StrategyType>[],
  responseFeeSlabs: Partial<FeeSlabsType>[],
  mdmsCompanyList: mdmsCompaniesList[],
  validateFatca = true,
  fatcaMasterData?: nonIndividualMdmsQuestionsFatca,
  authorizedSignatoriesReferenceDetail?: getAuthorizedSignatoriesDetailsByRefrenceType
) => {
  const {
    applicants = [],
    banks = [],
    groups = [],
    ubo_declaration_type = '',
    ubo_declaration_value = '',
    ubos = [],
    applicationType = '',
    createdAt = '',
  } = application || {};
  const { micrValidation = '' } = validationOrConfigChecks || {};
  const nationalityDropdown = mdmsCountriesList.map((list) => list.nationality);
  const countryDropdown = mdmsCountriesList.map((list) => list.name);
  try {
    await validateNonIndividualContributorDetails(applicants);
    await validateNonIndividualContactDetails(applicants, countryDropdown);
    await validateBankDetails(
      banks,
      verifyPennydrop,
      applicationType,
      applicants,
      createdAt,
      micrValidation
    );
    await validateNonIndividualDocumentDetails(applicants, documentsData, banks);
    await validateNonIndividualAuthorisedSignatories(groups);
    await validateNonIndividualUbo(
      applicants,
      ubo_declaration_type,
      ubo_declaration_value,
      ubos,
      nationalityDropdown,
      countryDropdown
    );
    await validateRiskProfile(application, riskProfileDataMaster, role, '', true);
    await validateStrategyDetails(application, strategyRes, responseFeeSlabs, role);
    await validateDisclosureOfInterest(application, '', true, mdmsCompanyList);
    await validateDisclusionOfExclusion(application, '', true, mdmsCompanyList);
    await validateRelatedParty(application);
    validateFatca &&
      fatcaMasterData &&
      (await validateNonIndividualFatca(applicants, fatcaMasterData, mdmsCountriesList));
  } catch (error) {
    if (error instanceof UboErrors) {
      throw (error as UboErrors).message;
    }
    if (error instanceof AuthorisedErrors) {
      throw (error as AuthorisedErrors).message;
    }
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    if (error instanceof RiskProfileErrors) {
      throw (error as RiskProfileErrors).message;
    }
    if (error instanceof StrategyErrors) {
      throw (error as StrategyErrors).message;
    }
    if (error instanceof RelatedPartyConsentErrors) {
      throw (error as RelatedPartyConsentErrors).message;
    }
    if (error instanceof fatcaErrors) {
      throw `${(error as fatcaErrors).message} in FATCA details`;
    }
    throw authorizedSignatoriesReferenceDetail?.referenceId
      ? `Required fields are not filled by Authorized Signatories`
      : `In ${error}, the required fields are not filled.`;
  }
};

export const getInvestorEditSections = (investorEditObj: Partial<InvestorEditErrorObj[]>) => ({
  type: 'GET_INVESTOR_EDIT_SECTION',
  investorEditObj,
});
let errorsArray: any = [];
export const FormValidationForDisplayCompleteApplicationButtonForInvestor = async (
  application: ApplicationProps | null,
  documentsResponse = {},
  role = '',
  riskProfileDataMaster: RiskProfileMasterType[],
  referenceDetail: getDetailsByRefrenceType,
  isValidateRiskProfile: boolean,
  isValidateDisclosureOfInterest: boolean,
  isValidateDisclosureOfExclusions: boolean,
  isValidateDocuments: boolean,
  mdmsCompanyList: mdmsCompaniesList[]
) => {
  const { applicants = [], nominees = [], hasPOA = true, banks = [] } = application || {};
  errorsArray =
    isValidateRiskProfile &&
    isValidateDisclosureOfInterest &&
    isValidateDisclosureOfExclusions &&
    isValidateDocuments
      ? []
      : errorsArray;
  try {
    isValidateRiskProfile &&
      (await validateRiskProfile(
        application,
        riskProfileDataMaster,
        role,
        referenceDetail.applicant_type,
        false
      ));
    isValidateDisclosureOfInterest &&
      (await validateDisclosureOfInterest(
        application,
        referenceDetail.applicant_type,
        false,
        mdmsCompanyList
      ));
    isValidateDisclosureOfExclusions &&
      (await validateDisclusionOfExclusion(
        application,
        referenceDetail.applicant_type,
        false,
        mdmsCompanyList
      ));
    isValidateDocuments &&
      Object.keys(documentsResponse).length !== 0 &&
      (await validateDocuments(
        applicants,
        hasPOA,
        documentsResponse as Document,
        nominees,
        referenceDetail.applicant_type,
        false,
        banks,
        true
      ));
    throw errorsArray;
  } catch (error) {
    if (error instanceof RiskProfileErrors) {
      errorsArray.push({
        ...investorEditRiskProfileObj,
        errorMessage: (error as RiskProfileErrors).message,
      });
      await FormValidationForDisplayCompleteApplicationButtonForInvestor(
        application,
        documentsResponse,
        role,
        riskProfileDataMaster,
        referenceDetail,
        false,
        true,
        true,
        true,
        mdmsCompanyList
      );
    } else if (
      error === 'Disclosure Of Interest' ||
      (APMIFlow && error instanceof DisclosureOfInterestErrors)
    ) {
      errorsArray.push({
        ...investorEditDisclosureOfInterestObj,
        errorMessage:
          error === 'Disclosure Of Interest'
            ? `In ${error}, the required fields are not filled.`
            : (error as DisclosureOfInterestErrors).message,
      });
      await FormValidationForDisplayCompleteApplicationButtonForInvestor(
        application,
        documentsResponse,
        role,
        riskProfileDataMaster,
        referenceDetail,
        false,
        false,
        true,
        true,
        mdmsCompanyList
      );
    } else if (
      error === 'Disclosure Of Exclusions' ||
      (APMIFlow && error instanceof DisclosureOfExclusionsErrors)
    ) {
      errorsArray.push({
        ...investorEditDisclosureOfExclusionObj,
        errorMessage:
          error === 'Disclosure Of Exclusions'
            ? `In ${error}, the required fields are not filled.`
            : (error as DisclosureOfExclusionsErrors).message,
      });
      await FormValidationForDisplayCompleteApplicationButtonForInvestor(
        application,
        documentsResponse,
        role,
        riskProfileDataMaster,
        referenceDetail,
        false,
        false,
        false,
        true,
        mdmsCompanyList
      );
    } else if (error === 'Document details') {
      errorsArray.push({
        ...investorEditDocumentDetailsObj,
        errorMessage: `In ${error}, the required fields are not filled.`,
      });
      throw errorsArray;
    } else {
      throw errorsArray;
    }
  }
};

export const getCompanies =
  () =>
  async (dispatch: any): Promise<Document> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/companyMaster.json',
        method: 'GET',
        types: [GET_COMPANY_SUCCESS, GET_COMPANY],
      },
    });
  };

export const secondCheckerApproveApplication =
  ({ body, applicationId }: { body: Partial<ApplicationProps>; applicationId: string }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/approve/${applicationId}`,
        method: 'POST',
        types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
        body,
      },
    });
  };

export const secondCheckerRejectApplication =
  ({ body, applicationId }: { body: Partial<ApplicationProps>; applicationId: string }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/reject/${applicationId}`,
        method: 'POST',
        types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
        body,
      },
    });
  };
